<template>
  <footer class="footer">
    <ul class="footer__menu footer-menu">
      <li class="footer-menu__item">
        <router-link
            to="/"
                     class="footer-menu__link footer-menu__link--home"
                     active-class="footer-menu__link--active"
                     >Главная</router-link>
      </li>
      <li class="footer-menu__item">
        <router-link class="footer-menu__link footer-menu__link--catalog" to="/catalog/" active-class="footer-menu__link--active">Каталог</router-link>
      </li>

      <li class="footer-menu__item">
        <router-link class="footer-menu__link footer-menu__link--fav" to="/favorite/" active-class="footer-menu__link--active">Избранное</router-link>
      </li>

      <li class="footer-menu__item">
        <router-link class="footer-menu__link footer-menu__link--personal" to="/personal/" active-class="footer-menu__link--active">Кабинет</router-link>
      </li>
    </ul>
  </footer>
</template>