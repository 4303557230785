import { createApp, defineAsyncComponent } from 'vue'
import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import router from './router'
import App from './App.vue'
import './style.css'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
/*
const Registration = () => import('./Registration.vue')

const routes = [
    { path: '/auth', component: Auth, name:'auth' },
    { path: '/test', component: Test },
    { path: '/personal/', component: Registration, name:'registration' },
    { path: '/catalog/', component: Catalog, name:'catalog' },
    { path: '/', component: Main, name:'main', meta: {layout: 'main'}, }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

router.beforeEach((to, from, next) => {

  // debugger;
    //if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
    //else
    next()
})
*/
let device_uuid = (new Date()).getTime();

if (localStorage.getItem('user-token'))
    device_uuid = localStorage.getItem('user-token');

localStorage.setItem('user-token', device_uuid)
if (device_uuid) {
    //axios.defaults.params = {Authorization: token}
    axios.defaults.headers.common['Authorization'] = device_uuid;
}

axios.defaults.baseURL = 'https://abcadmin.ru';
//axios.defaults.baseURL = 'https://pivo.alm.su';

document.addEventListener('deviceready', onDeviceReady, false);
function onDeviceReady() {

    if (typeof device == 'object') {
        device_uuid = device.uuid;
        //axios.defaults.params = {Authorization: device.uuid}
        axios.defaults.headers.common['Authorization'] = device.uuid;

        if (device.platform.indexOf("iOS") != -1) {
            //document.body.classList.add('ios');
            if (device.model.indexOf("iPhone10") != -1
                || device.model.indexOf("iPhone11") != -1
                || device.model.indexOf("iPhone12") != -1
                || device.model.indexOf("iPhone13") != -1
                || device.model.indexOf("iPhone14") != -1
                || device.model.indexOf("iPhone15") != -1
                || device.model.indexOf("iPhone16") != -1
                || device.model.indexOf("iPhone17") != -1)
                document.body.classList.add('ios');
        }

    }

    const app2 = createApp(App, {device_uuid: device_uuid})
// Make sure to _use_ the router instance to make the
// whole app router-aware.
    app2.use(router)
    app2.use(store)
    app2.use(VueAxios, axios)
    app2.mount('#app')

    document.addEventListener("offline", onOffline, false);
    document.addEventListener("online", onOnline, false);
    onOnline();
    function onOnline() {
        // Handle the online event
        var networkState = navigator.connection.type;
        console.log("networkState "+networkState);
    }

    function onOffline() {
        // Handle the offline event
        console.log("lost connection");
    }
}
/*if (navigator.userAgent.indexOf("Windows") != -1 || navigator.userAgent.indexOf("Safari") != -1)
    onDeviceReady();
*/
