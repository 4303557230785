<template>
  <button v-if="!itembasket" @click="favorite" class="product__corner-btn product__corner-btn--fav" :class="{'product__corner-btn--fav-active':item.favorite}"></button>
  <button v-if="itembasket" @click="deleteItem" class="product__corner-btn product__corner-btn--remove"></button>

  <div class="product__left">
    <div class="product__img-block">
      <img v-if="item.image" class="product__img" :src="item.image" >
    </div>

    <div class="product__left-bottom">
      <span v-if="item.untappd" class="product__rating">{{item.untappd}}</span>

      <div v-if="item.hit || item.new || item.sale" class="product__tags">
        <span v-if="item.hit" class="product__tag product__tag--hit">ХИТ</span>
        <span v-if="item.new" class="product__tag product__tag--new">NEW</span>
        <span v-if="item.sale" class="product__tag product__tag--sale">SALE</span>
      </div>

      <div class="product__prices">
        <span class="product__price">{{item.price}}&nbsp;₽</span>
        <span v-if="item.old_price > item.price" class="product__price product__price--old">{{item.old_price}}&nbsp;₽</span>
      </div>
    </div>
  </div>

  <div class="product__right">
    <router-link tag="span" :to="'/detail/'+item.id" class="product__name">{{ item.name }}</router-link>

    <div class="product__additional-props">
      <span class="product__additional-prop">{{item.stil}}</span>
    </div>

    <div class="product__props">
      <span class="product__package-prop">{{item.tip_upakovki}} {{item.volume_t}}</span>

      <div class="product__number-props">
        <p class="product__number-prop">
          <span class="product__number-prop-value">{{item.abv}}</span>
          <span class="product__number-prop-name">ABV</span>
        </p>

        <p class="product__number-prop">
          <span class="product__number-prop-value">{{item.og}}</span>
          <span class="product__number-prop-name">OG</span>
        </p>

        <p class="product__number-prop">
          <span class="product__number-prop-value">{{item.ibu}}</span>
          <span class="product__number-prop-name">IBU</span>
        </p>
      </div>
    </div>

    <div class="product__brand" v-if="item.brand != null">
      <div v-if="item.brand.image" class="product__brand-img-block">
        <img class="product__brand-img" :src="item.brand.image" :alt="item.brand.name">
      </div>
      <span class="product__brand-name">{{item.brand.name}}</span>
    </div>

    <div v-if="item.quantity > 0" class="product__controls product__controls--item" :class="{'product__controls--active':quantity>0}">
      <div class="product__controls-btns">
        <button @click="changeQuantity('minus')" class="product__controls-btn product__controls-btn--minus" :class="{'product__controls-btn--disabled':quantity==0}"></button>
        <input v-model="quantity" class="product__controls-counter" type="number" disabled="">
        <button @click="changeQuantity('plus')" class="product__controls-btn product__controls-btn--plus" :class="{'product__controls-btn--disabled':(item.quantity <= basket)}"></button>
      </div>

      <button @click="changeQuantityFive" class="product__controls-plus-five" :class="{'product__controls-plus-five--disabled':(item.quantity <= quantity)}"></button>

      <span v-if="quantity > 0" class="product__controls-total">{{ item.price*quantity }}&nbsp;₽</span>
    </div>

    <div v-if="item.quantity > 0 && item.quantity <= basket" class="product__alert-block">
      <span>Добавлено максимальное количество</span>
    </div>
    <div v-if="item.quantity == 0" class="product__alert-block">
      <span>Не осталось</span>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    item: {},
    itembasket: false,
    itemorder: false
  },
  data: () => ({
    quantity: 0
  }),
  computed: {
    basket() {
      let basket_quantity = this.$store.getters.inBasket(this.$props.item.id);
      if (basket_quantity != this.quantity)
        this.quantity = basket_quantity;
      return basket_quantity;
    }
  },
  watch: {
    basket(val) {
      if (val != this.quantity)
        this.quantity = val;
    }
  },
  created() {

  },
  methods: {
    favorite() {
      if (this.$props.item.favorite) {
        this.$store.dispatch({type:'deleteFavorite', id: this.$props.item.id})
      }
      else {
        this.$store.dispatch({type:'addFavorite', id: this.$props.item.id})
      }
      this.$props.item.favorite = !this.$props.item.favorite;
    },
    changeQuantity(type) {
      let act = '';
      let quantity = this.quantity;
      if (type == 'plus') {
        if (this.$props.item.quantity <= this.basket)
          return;
        if (quantity == 0)
          act = 'add';
        else
          act = 'update';
        quantity++;
      }
      else {
        if (quantity > 1) {
          act = 'update';
        }
        else if (quantity == 1) {
          act = 'delete';
        }
        if (act)
          quantity--;
      }
      if (act == 'add')
        this.$store.dispatch({type:'addBasket', id: this.$props.item.id, quantity: quantity})
      else if (act == 'update')
        this.$store.dispatch({type:'updateBasket', id: this.$props.item.id, quantity: quantity})
      else if (act == 'delete')
        this.$store.dispatch({type:'deleteBasket', id: this.$props.item.id, quantity: quantity})
    },
    changeQuantityFive() {
      if (this.$props.item.quantity <= this.quantity)
        return;
      let quantity = this.quantity,
          act = '';

      if (quantity == 0) {
          act = 'add';
      } else {
          act = 'update'
      }

      for (let i = 0; i < 5; i++) {
        if (this.$props.item.quantity > this.quantity) {
          quantity++;
          this.quantity = quantity;
        } else {
          break;
        }
      }

      if (act == 'add')
          this.$store.dispatch({type:'addBasket', id: this.$props.item.id, quantity: quantity})
      else if (act == 'update')
          this.$store.dispatch({type:'updateBasket', id: this.$props.item.id, quantity: quantity})

    },
    deleteItem() {
      this.$store.dispatch({type:'deleteBasket', id: this.$props.item.id, quantity: 0})
    }
  },
  components: {

  }
}
</script>