<template>
  <main class="main">
    <section class="section" v-if="props">
      <div class="site-container">
        <div class="order">
          <div class="order__total">
            <div class="order__total-sum-row">
              <span class="order__total-sum-heading">Сумма к оплате</span>
              <span class="order__total-sum-value">{{ props.sum }}&nbsp;₽</span>
            </div>

            <div class="order__total-counter-row">
                            <span class="order__total-counter">
                                <span class="order__total-counter-value">{{ props.quantity }}</span> {{ declOfNum( props.quantity, ['наименование','наименования','наименований']) }}
                            </span>
              <span v-if="props.discount > 0" class="order__total-discount">Скидка {{ props.discount }}&nbsp;₽</span>
            </div>
          </div>

          <div v-show="error.length > 0" class="info-block info-block--alert">
            <h3>Внимание</h3>
            <p v-for="item in error">{{item}}</p>
          </div>

          <div class="order__form-block">
            <form class="order__form-block form" action="#" method="post" @submit.prevent="sendOrder">
              <div class="form__input-block">
                <label class="form__input-label">
                  ФИО контактного лица<span class="form__input-required"> *</span>
                </label>
                <div class="form__input-wrapper">
                  <input v-model="fio" required class="form__input input" type="text" placeholder="Введите данные">
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label">
                  Контактный телефон<span class="form__input-required"> *</span>
                </label>
                <div class="form__input-wrapper">
                  <InputMask  v-model="phone" mask="+7 (999) 999-99-99" required class="form__input input" type="text" placeholder="+7 (___) ___-__-__" />
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label" for="formInput3">
                  E-mail<span class="form__input-required"> *</span>
                </label>
                <div class="form__input-wrapper">
                  <input v-model="email" required class="form__input input" type="text" placeholder="Введите данные">
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label">
                  Укажите адрес<span class="form__input-required"> *</span>
                </label>
                <div class="form__input-wrapper">
                  <input v-model="address" required class="form__input input" type="text" placeholder="Введите данные">
                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label">
                  Время приёма отгрузок<span class="form__input-required"> *</span>
                </label>
                <div class="form__input-wrapper">
                  <Select2 class="form__input--half" v-model="priem_from" :options="from_options"  @select="mySelectEvent($event)" placeholder="с --:--" :settings="{minimumResultsForSearch: -1, dropdownPosition: 'below', width: '100%'}" />

                  <Select2 class="form__input--half" v-model="priem_to" :options="to_options" placeholder="до --:--" :settings="{minimumResultsForSearch: -1, dropdownPosition: 'below', width: '100%'}" />

                </div>
              </div>

              <div class="form__input-block">
                <label class="form__input-label">
                  Дата доставки<span class="form__input-required"> *</span>
                </label>
                <Select2 class="form__input-wrapper" v-model="date" :options="props.dates" placeholder="Выбрать" :settings="{minimumResultsForSearch: -1, dropdownPosition: 'below', width: '100%'}" />
              </div>

              <div class="form__input-block">
                <label class="form__input-label">
                  Комментарий
                </label>
                <div class="form__input-wrapper">
                  <textarea v-model="comment" class="form__input form__input--textarea textarea" placeholder="Напишите, что нам нужно знать"></textarea>
                </div>
              </div>

              <button class="form__submit-btn btn" type="submit">Подтвердить заказ</button>
            </form>
          </div>

          <div class="order__info">
            <div class="order__info-block info-block info-block--success info-block--centered">
              <h3>Помощь по любым вопросам</h3>
              <p>
                Обращайтесь к вашему менеджеру
              </p>
              <a href="tel:+79000000000">+7 (900) 000-00-00</a>
              <span>Алексей</span>
            </div>

            <div class="order__info-block info-block info-block--info">
              <h3>Информация о доставке</h3>
              <p>
                Задача организации, в особенности же убеждённость некоторых оппонентов в значительной степени обусловливает важность прогресса профессионального сообщества. Идейные соображения высшего порядка, а также глубокий уровень погружения однозначно определяет каждого участника как способного принимать собственные решения касаемо анализа существующих паттернов поведения.

              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import Select2 from 'vue3-select2-component';
import InputMask from 'primevue/inputmask';

export default {
  data: () => ({
    error: false,
    fio: '',
    email: '',
    phone: '',
    comment: '',
    priem: 'Y',
    priem_options: [],
    address: '',
    priem_from: '',
    priem_to: '',
    date: '',
    from_options: ['10:00', '11:00', '12:00','13:00', '14:00', '15:00','16:00', '17:00', '18:00','19:00', '20:00', '21:00'],
    to_options_orig: ['11:00', '12:00','13:00', '14:00', '15:00','16:00', '17:00', '18:00','19:00', '20:00', '21:00', '22:00'],
    to_options: ['11:00', '12:00','13:00', '14:00', '15:00','16:00', '17:00', '18:00','19:00', '20:00', '21:00', '22:00'] // or [{id: key, text: value}, {id: key, text: value}]
  }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    props() {
      let props = this.$store.getters.orderProps;
      if (typeof props == 'object') {
        if (props.quantity == 0)
          this.$router.push('/basket');
        this.fio = props.user.NAME;
        this.email = props.user.EMAIL;
        this.phone = props.user.PERSONAL_PHONE;
        this.address = props.user.UF_G_ADDRESS;
        this.priem_options = [{id:'Y', text: 'Новый'}];
        for (let i in props.priem) {
          this.priem_options.push({id: props.priem[i].ID, text: props.priem[i].UF_ADDRESS});
        }
      }
      return this.$store.getters.orderProps;
    }
  },
  methods: {
    async getProps() {
      await this.$store.dispatch('fetchOrderProps')
    },
    selectPriem(event) {
      if (event.id > 0) {
        let priem = this.props.priem[event.id];
        this.address = priem.UF_ADDRESS;
        this.priem_from = priem.UF_FROM;
        this.priem_to = priem.UF_TO;
      }
      else {
        this.address = '';
        this.priem_from = '';
        this.priem_to = '';
      }
    },
    mySelectEvent(event) {
      let to_options = [];
      for (let item in this.to_options_orig) {
        if (this.to_options_orig[item] > event.text === true)
          to_options.push(this.to_options_orig[item])
      }
      this.to_options = to_options;
    },
    sendOrder() {
      this.error = [];
      if (this.priem_from == false)
        this.error.push('Необходимо заполнить Время приема отгрузок с');
      if (this.priem_to == false)
        this.error.push('Необходимо заполнить Время приема отгрузок до');
      if (this.date == false)
        this.error.push('Необходимо заполнить Дату доставки');
      if (this.error.length > 0) {
        $(window).scrollTop($(".info-block--alert").offset().top);
        return;
      }
      let formData = new FormData();
      formData.append('email', this.email);
      formData.append('phone', this.phone);
      formData.append('fio', this.fio);
      formData.append('to', this.priem_to);
      formData.append('from', this.priem_from);
      formData.append('date', this.date);
      formData.append('priem', this.priem);
      formData.append('address', this.address);
      formData.append('comment', this.comment);

      let _this = this;
      axios.post( '/local/api/order/add',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        if (res.data.error) {
          _this.error = res.data.error;
          $(window).scrollTop($(".info-block--alert").offset().top);
        }
        if (res.data.status == 200) {
          let orderInfo = {
            id: res.data.id,
            sum: _this.props.sum,
            fio: _this.fio,
            phone: _this.phone,
            email: _this.email,
            address: _this.address,
            date: _this.date,
            time: _this.priem_from+'-'+_this.priem_to,
            comment: _this.comment
          }
          _this.$store.commit('orderInfo', orderInfo);
          _this.$router.push('/order-complete/'+res.data.id);
        }
      })
          .catch(function(){

          });
    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    }
  },
  created() {
    this.getProps();
  },
  components: {Select2, InputMask}
}
</script>

