<template>
  <section class="section" v-if="sliderLoad">
    <div class="site-container">
      <div class="index-slider slider">
        <div class="index-slider__items slider__items">
          <swiper :modules="modules" :pagination="true">
            <template v-for="slide in slides">
            <swiper-slide>
              <router-link :to="slide.url" class="index-slider__item slider__item" :style="'background-image: url('+slide.background+');'">
                <span class="index-slider__item-text" style="color: #fff;">{{ slide.title}}</span>
                <img class="index-slider__item-logo" :src="slide.image" alt="{{ slide.title}}">
              </router-link>
            </swiper-slide>
            </template>
          </swiper>
        </div>

        <div class="slider__dots"></div>
      </div>
    </div>
  </section>
</template>
<script>
import {ref, onUpdated, watch, onMounted} from 'vue'
import {useRouter} from "vue-router";

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';

export default {
  props: [

  ],
  data: () => ({
    modules: [Pagination],
    sliderLoad: false,
    slides: false
  }),
  computed: {

  },
  async created() {
    this.slides = await this.slider();
    this.sliderLoad = true;
  },
  methods: {
    async slider() {
     // if (this.$store.getters.slider === false)
        await this.$store.dispatch('fetchSlider')
      return this.$store.getters.slider;
    }
  },
  components: {
    Swiper,
    SwiperSlide
  }
}
</script>