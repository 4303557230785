<template>
  <main class="main">
    <section class="section">
      <div class="site-container">
        <Elements
            :brands="brands"
            id="favorite"
            :sort="sort"
            :search="search"
        ></Elements>
        <p v-if="!brands">Вы пока ничего не добавили в избранное</p>
      </div>

    </section>
  </main>
</template>

<script>
import Elements from '../components/app/Elements'

export default {
  data: () => ({
    sort: 'name_asc',
    search: ''
  }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  components: {Elements},
  computed: {
    brands() {
      return this.$store.getters.section('favorite');
    },
  },
  async created() {
    // if (this.$store.getters.section[this.$route.params.id] === false || this.$store.getters.section[this.$route.params.id] == undefined)
    await this.$store.dispatch({type:'fetchFavorite'})
  },
}
</script>

