<template>
  <main class="main">
    <section class="section">
      <div class="site-container">
        <div class="cabinet">
          <div v-if="user" class="cabinet__pairs pairs">
            <p class="pairs__pair">
              <span class="pairs__pair-name">Заведение</span>
              <span class="pairs__pair-value">{{user.UF_NAME}}</span>
            </p>

            <p class="pairs__pair">
              <span class="pairs__pair-name">ФИО контактного лица</span>
              <span class="pairs__pair-value">{{user.NAME}}</span>
            </p>

            <p class="pairs__pair">
              <span class="pairs__pair-name">Телефон</span>
              <span class="pairs__pair-value">{{user.PERSONAL_PHONE}}</span>
            </p>

            <p v-if="false" class="pairs__pair">
              <span class="pairs__pair-name">Адрес доставки</span>
              <span class="pairs__pair-value">{{user.UF_ADDRESS}}</span>
            </p>
          </div>

          <div class="cabinet__links">
            <router-link to="/orders" class="cabinet__link" href="#">Мои заказы</router-link>
            <router-link to="/refund" class="cabinet__link" href="#">Запросы на возврат</router-link>
            <router-link to="/change-password" class="cabinet__link" href="#">Изменить пароль</router-link>
            <router-link to="/profile" class="cabinet__link" href="#">Мои данные</router-link>
            <router-link to="/about" class="cabinet__link" href="#">Информация о компании ABC</router-link>
          </div>

          <HelpManager></HelpManager>

          <div class="cabinet__leave links-block">
            <a @click="logout" class="links-block__link btn btn--non-target" href="javascript:;" id="cabinetExit">Выйти из аккаунта</a>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import HelpManager from "../components/app/HelpManager"

export default {
  props: [

  ],
  data: () => ({

  }),
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  created() {

  },
  mounted() {
    this.$store.dispatch('Loader');
  },
  methods: {
    async logout() {
      const { data } = await axios.post('/local/api/logout', {
        act: 'logout'
      });
      if (data.is_auth === false) {
        this.$store.dispatch('Logout')
        this.$router.push('/login');
      }
      else
        console.log(data)
    }
  },
  components: {
    HelpManager
  }
}
</script>

