<template>
    <section v-if="order" class="section">
      <div class="site-container">
        <div class="order-request-detail">
          <h2 class="order-request-detail__heading section__heading">Заказ №{{ order.number }}</h2>

          <div v-if="order.comment_manager" class="order-request-detail__info-block info-block info-block--alert">
            <h3>Внимание</h3>
            <p>
              {{order.comment_manager}}
            </p>
          </div>

          <div class="order-request-detail__total-short">
            <div class="order-request-detail__total-short-row">
              <span class="order-request-detail__total-short-sum-text">Итого</span>
              <span class="order-request-detail__total-short-sum-value">{{ order.sum }}&nbsp;₽</span>
            </div>

            <div class="order-request-detail__total-short-row">
              <span class="order-request-detail__total-short-date">{{ order.date_create }}</span>
              <span class="order-request-detail__total-short-status" :class="getStatusCode(order.status_id)">{{ order.status }}</span>
            </div>

            <div class="order-request-detail__total-short-row">
              <span class="order-request-detail__total-short-count">{{ order.quantity }} {{ declOfNum(order.quantity, ['товар','товара','товаров']) }}</span>
            </div>
          </div>

          <div v-if="basket" class="order-request-detail__products">
            <template v-for="item in basket">
              <div v-if="item" class="order-request-detail__product product" :class="{'product--disabled':false}">
                <div class="product__left">
                  <div v-if="item.product.image" class="product__img-block">
                    <img class="product__img" :src="item.product.image" alt="Baby, Did You Forget To Take Your Meads? Raspberry Cream">
                  </div>

                  <div class="product__left-bottom">
                    <span v-if="item.product.untappd" class="product__rating">{{item.product.untappd}}</span>

                    <div class="product__prices">
                      <span class="product__price">{{item.order.price}}&nbsp;₽</span>
                      <span v-if="item.order.base_price > item.order.price" class="product__price product__price--old">{{item.order.base_price}}&nbsp;₽</span>
                    </div>
                  </div>
                </div>

                <div class="product__right">
                  <router-link v-if="item.product.active === 'Y' && item.product.available === 'Y'" :to="'/detail/'+item.product.id" class="product__name">{{ item.product.name }} </router-link>
                  <div v-else  class="product__name">{{ item.product.name }}</div>

                  <div class="product__additional-props">
                    <span class="product__additional-prop">арт. {{item.product.artnumber}}</span>
                    <span class="product__additional-prop">{{item.product.stil}}</span>
                  </div>

                  <div class="product__props">
                    <span class="product__package-prop">{{item.product.tip_upakovki}} {{item.product.volume_t}}</span>


                  </div>

                  <div class="product__brand">
                    <div class="product__brand-img-block">
                      <img v-if="item.product.brand.image" class="product__brand-img" :src="item.product.brand.image" :alt="item.product.brand.name">
                    </div>
                    <span class="product__brand-name">{{item.product.brand.name}}</span>
                  </div>

                  <div class="product__controls">
                    <span class="product__simple-count">{{ item.order.quantity}} шт.</span>

                    <span class="product__controls-total">{{ item.order.final_price }}&nbsp;₽</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div v-if="error" class="info-block info-block--alert">
            <h3>Внимание</h3>
            <p v-for="item in error">{{item}}</p>
          </div>
          <div class="order-request-detail__links-block links-block">
            <router-link :to="'/orderdocs/'+order.id" class="links-block__link btn btn--non-target" href="#">Сформировать ТТН и счет</router-link>
            <a @click="repeat" class="links-block__link btn btn--non-target" href="#">Повторить заказ</a>
            <router-link :to="'/refund/add/'+order.id" class="links-block__link btn btn--non-target" href="#">Подать запрос на возврат</router-link>
          </div>

          <div class="order-request-detail__requisites requisites-block">
            <div v-if="property" class="requisites-block__items">
              <p class="requisites-block__pair">
                <span class="requisites-block__pair-name">Сумма к оплате</span>
                <span class="requisites-block__pair-value requisites-block__pair-value--bold">{{ order.sum }}&nbsp;₽</span>
              </p>

              <p class="requisites-block__pair">
                <span class="requisites-block__pair-name">ФИО контактного лица</span>
                <span class="requisites-block__pair-value">{{ property.CONTACT_PERSON }}</span>
              </p>

              <p class="requisites-block__pair">
                <span class="requisites-block__pair-name">Контактный телефон</span>
                <span class="requisites-block__pair-value">{{ property.PHONE }}</span>
              </p>

              <p class="requisites-block__pair">
                <span class="requisites-block__pair-name">E-mail</span>
                <span class="requisites-block__pair-value">{{ property.EMAIL }}</span>
              </p>

              <p class="requisites-block__pair">
                <span class="requisites-block__pair-name">Адрес доставки</span>
                <span class="requisites-block__pair-value">{{ property.ADDRESS }}</span>
              </p>

              <p class="requisites-block__pair">
                <span class="requisites-block__pair-name">Время приема отгрузок</span>
                <span class="requisites-block__pair-value">{{ property.FROM }} - {{ property.TO }}</span>
              </p>

              <p class="requisites-block__pair">
                <span class="requisites-block__pair-name">Комментарий</span>
                <span class="requisites-block__pair-value">{{ order.comment }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import ProductItem from '../components/app/ProductItem'
import axios from "axios";

export default {
  data: () => ({
    property: false,
    basket: false,
    error: false,
    statuses: {
      'OTHER': 'order-request-detail__total-short-status--pending',
      'F': 'order-request-detail__total-short-status--done',
      'C': 'order-request-detail__total-short-status--canceled',
      'V': 'order-request-detail__total-short-status--canceled'
    }
  }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    order() {
      if (this.$store.getters.orderDetail.property != undefined)
        this.property = this.$store.getters.orderDetail.property;
      if (this.$store.getters.orderDetail.basket != undefined)
        this.basket = this.$store.getters.orderDetail.basket;
      //console.log(this.$store.getters.orderDetail.property.CONTACT_PERSON)
      return this.$store.getters.orderDetail;
    }
  },
  created() {
    this.$store.dispatch('clearOrderDetail');
    this.getDetail();
  },
  components: {ProductItem},
  methods: {
    async getDetail() {
      await this.$store.dispatch({type: 'fetchOrderDetail', id:this.$route.params.id})
    },
    getStatusCode(id) {
      if (typeof this.statuses[id] == 'string')
        return this.statuses[id];
      else
        return this.statuses['OTHER'];
    },
    repeat() {
      this.error = false;
      let _this = this;
      axios.post( '/local/api/order/repeat',
          {
            id: this.$route.params.id
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        if (res.data.error) {
          _this.error = res.data.error;
          $(window).scrollTop($(".info-block--alert").offset().top);
        }
        if (res.data.status == 200) {
          _this.$router.push('/basket');
        }
      })
          .catch(function(){

          });
    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    }
  },
}
</script>

